var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-container"},[_c('div',{staticClass:"d-flex top-bar pt-2"},[_c('div',{staticClass:"ml-2"},[_c('v-text-field',{staticClass:"searchbar shrink",attrs:{"placeholder":"search...","outlined":"","dense":"","prepend-inner-icon":"mdi-magnify","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer')],1),_vm._m(0),_c('div',{staticClass:"d-flex mt-5 pa-5",staticStyle:{"background-color":"#eff0fa","border-radius":"5px"}},[_c('div',[_vm._m(1),_c('div',{staticClass:"d-flex align-start justify-start total-amount"},[_vm._v(" "+_vm._s(_vm.getAllOrders.length)+" ")])]),_c('v-spacer'),_c('div',{staticClass:"d-flex align-end justify-end"})],1),_c('div',{staticClass:"d-flex mt-3"},[_vm._m(2),_c('v-spacer'),_c('div',{staticClass:"mr-3"},[_c('date-picker')],1)],1),_c('div',{staticClass:"mt-3"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.getAllOrders,"items-per-page":5,"search":_vm.search,"loading":_vm.tableloading,"hide-default-header":"","height":"calc(100vh - 400px)","mobile-breakpoint":0},scopedSlots:_vm._u([{key:"body.prepend",fn:function(ref){
var headers = ref.headers;
return _vm._l((headers),function(header,i){return _c('th',{key:'A' + i,staticClass:"table-head"},[_c('div',{staticClass:"d-flex ml-3"},[_vm._v(" "+_vm._s(header.text)+" ")])])})}},{key:"item.gas_quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.amount)+" kg ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" ₦ "+_vm._s(Number(item.amount).toLocaleString())+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","color":item.status == 'Unpaid' ? 'red' : 'green',"label":"","outlined":""}},[_vm._v(_vm._s(item.status))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.ViewOrders(item)}}},[_vm._v(" mdi-eye ")])]}}],null,true)})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex mt-5"},[_c('div',[_c('b',[_vm._v(" Orders")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-start justify-start",staticStyle:{"color":"#2b3896"}},[_c('b',[_vm._v("Pending")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4"},[_c('b',[_vm._v("History")])])}]

export { render, staticRenderFns }